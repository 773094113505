import fontkit from '@pdf-lib/fontkit'
// import { saveAs } from 'file-saver'
import { PDFDocument, rgb } from 'pdf-lib'

const fontUrl = `${window.location.origin}/fonts/Times-New-Roman.ttf`

export const generatePDF = async (data: any, templateUrl: string) => {
  const existingPdfBytes = await fetch(templateUrl).then((res) => res.arrayBuffer())

  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  pdfDoc.registerFontkit(fontkit)

  const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())
  const customFont = await pdfDoc.embedFont(fontBytes)
  // const symbolFont = await pdfDoc.embedFont(StandardFonts.Symbol)
  // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const pages = pdfDoc.getPages()

  const fontSize = 11
  const color = rgb(0, 0, 0)

  pages.forEach((page, index) => {
    if (index === 0) {
      page.drawText(`${data.patient.name}`, { x: 125, y: 643.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.dob}`, { x: 378, y: 643.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.job}`, { x: 135, y: 628.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.education}`, { x: 408, y: 628.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.gender}`, { x: 118, y: 614.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.nation}`, { x: 412, y: 614.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.contact}`, { x: 186, y: 599, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.address}`, { x: 382, y: 599, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.contactPerson}`, { x: 261, y: 585, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.firstExam}`, { x: 167, y: 570.8, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.reason}`, { x: 404, y: 570.8, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.history}`, { x: 115, y: 556, size: fontSize, font: customFont, color })
      page.drawText(`${data.weight} kg`, { x: 260, y: 516, size: fontSize, font: customFont, color })
      page.drawText(`${data.height} cm`, { x: 414, y: 516, size: fontSize, font: customFont, color })
      if (data.sick.diung) {
        page.drawText('v', { x: 130, y: 502, size: fontSize, font: customFont, color })
      }
      page.drawText('v', { x: 243, y: 503, size: fontSize, font: customFont, color })
    } else if (index === 1) {
      // page.drawText(`Nghề nghiệp: ${data.patient.job}`, { x: 100, y: 700, size: 12, font: customFont, color })
    }
  })

  const pdfBytes = await pdfDoc.save()

  const blob = new Blob([pdfBytes], { type: 'application/pdf' })

  const blobUrl = URL.createObjectURL(blob)
  window.open(blobUrl, '_blank')

  // saveAs(blob, 'medical_record.pdf')
}
