/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import 'react-quill/dist/quill.snow.css'

import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { getInfo, updateInfo } from './reducers/infoReducer'

const Facebook: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [value, setValue] = useState<string>('<p>First</p>')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const isAdmin = useSelector((state: RootState) => state.auth.user?.role === 9)

  const fetchInfo = async () => {
    try {
      const rs = await dispatch(getInfo()).unwrap()
      setValue(rs.content)
    } catch (error) {}
  }

  const handleSave = async () => {
    try {
      const rs = await dispatch(updateInfo({ content: value })).unwrap()

      if (rs) {
        setValue(rs.content)
        setIsEditing(false)
      }
    } catch (error) {}
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  return (
    <div>
      {isAdmin && (
        <div className='mb-3 flex justify-end'>
          <Button onClick={toggleEdit} type='default'>
            {isEditing ? 'Xem' : 'Chỉnh sửa'}
          </Button>
        </div>
      )}

      {/* Nếu admin và đang chỉnh sửa thì hiển thị editor */}
      {isAdmin && isEditing ? (
        <>
          <ReactQuill theme='snow' value={value} onChange={setValue} />

          <div className='mt-3 flex justify-end'>
            <Button onClick={handleSave} type='primary'>
              Lưu
            </Button>
          </div>
        </>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      )}
    </div>
  )
}

export default Facebook
