import { Button, Input, Table } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import type { RootState } from '@/store'

import { medicalList, setFilter } from './reducers/medicalReducer'

const DoctorManager: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const items = useSelector((state: RootState) => state.medical.items) as any[]
  const query = useSelector((state: RootState) => state.medical.query) as string
  const [value, setValue] = useState<string>(query || '')
  const [loading, setLoading] = useState<boolean>(true)

  const fectchData = async () => {
    setLoading(true)
    try {
      await dispatch(medicalList())
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fectchData()
  }, [query])

  const columns = [
    {
      title: 'Mã hồ sơ',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Bệnh nhân',
      dataIndex: 'patient',
      render: (record: any) => record?.name
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'patient',
      render: (record: any) => record?.dob
    },
    {
      title: 'Sdt/Email',
      dataIndex: 'patient',
      render: (record: any) => record?.contact
    },
    {
      title: 'Bác sĩ',
      dataIndex: 'doctorName',
      key: 'doctorName'
    },
    {
      title: 'Ngày tạo hồ sơ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record: any) => new Date(record).toLocaleDateString(),
      width: '20%'
    }
  ]

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(setFilter(text))
    }, 500),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setValue(text)
    handleSearch(text)
  }

  return (
    <div>
      <div className='mb-4 flex justify-end gap-2'>
        <Input className='w-[250px]' value={value} onChange={handleChange} placeholder='Search' prefix={<MdSearch />} />
        <Button type='primary' onClick={() => navigate('add-medical')}>
          Thêm hồ sơ
        </Button>
      </div>
      <Table
        onRow={(record: any) => {
          return {
            onClick: () => navigate(`${record.id}`)
          }
        }}
        dataSource={items}
        columns={columns}
        rowKey='id'
        loading={loading}
        rowClassName='cursor-pointer'
      />
    </div>
  )
}

export default DoctorManager
