import React from 'react'

import DoctorManager from './DoctorManager'
import AddDepartment from './partials/create/AddDepartment'
import AddDoctor from './partials/create/AddDoctor'

export const DoctorRoutes = [
  {
    path: 'doctors',
    element: <DoctorManager />
  },
  {
    path: 'doctors/add-doctor',
    element: <AddDoctor />,
    children: [
      {
        path: 'add-department',
        element: <AddDepartment />
      }
    ]
  }
]
