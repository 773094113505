import { customAlphabet } from 'nanoid'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const getNanoId = (length = 4): string => {
  const nanoid = customAlphabet(alphabet, length)
  return nanoid()
}

export const genCode = (): string => {
  const now = new Date()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const year = now.getFullYear().toString().slice(-2)

  return `${year}${month}${day}${getNanoId(4)}`
}
