import '@/styles/index.scss'

import { App } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import type IUser from '@/common/types/user.type'
import Error404 from '@/layout/Error404'
import MainLayout from '@/layout/Layout'
import LoginPage from '@/modules/auth/LoginPage'
import type { RootState } from '@/store'

import { AccountRoutes } from './modules/account/AccountRoutes'
import { DoctorRoutes } from './modules/doctor/DoctorRoutes'
import Facebook from './modules/info/Facebook'
import { MedicalRoutes } from './modules/medical-record/MedicalRoutes'
import Organization from './modules/organization/Organization'

const AppRoot: React.FC = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const user: IUser = useSelector((state: RootState) => state.auth.user)

  const routesConfig: any = [
    {
      path: 'info',
      element: <Facebook />
    },
    ...DoctorRoutes,
    ...MedicalRoutes,
    {
      path: 'organization',
      element: <Organization />
    },
    ...AccountRoutes
  ]

  const renderRoutes = (routes: any[]) =>
    routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ))

  return (
    <App message={{ maxCount: 1 }}>
      <Routes>
        <Route path='/' element={isLoggedIn && user ? <MainLayout /> : <Navigate to='/login' />}>
          <Route index element={<Navigate to='/doctors' />} />

          {renderRoutes(routesConfig)}

          <Route path='*' element={<Error404 />} />
        </Route>
        <Route path='/login' element={<LoginPage />} />
      </Routes>
    </App>
  )
}

export default AppRoot
