import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

import { initialMedicalFormState, type MedicalFormState } from '../common/constant'
import { genCode } from '../common/helper'

export interface DoctorState {
  items: any[]
  total: number
}

export interface MedicalRecord {
  items: any[]
  total: number
  query?: string
  dataState: MedicalFormState
  detail: MedicalFormState
  editState: MedicalFormState
  allDetail: any
  doctorId?: string
}

const initialState: MedicalRecord = {
  items: [],
  total: 0,
  query: undefined,
  dataState: {
    // page 1
    code: genCode(),
    student: '',
    patient: {
      name: '',
      dob: '',
      job: '',
      education: '',
      gender: '',
      nation: '',
      contact: '',
      address: '',
      contactPerson: '',
      firstExam: '',
      reason: '',
      history: 'Không'
    },
    weight: '',
    height: '',
    sick: {
      diung: false,
      bamsinh: false,
      hutthuoc: false,
      ruou: false,
      maydieuhoa: false,
      stentmachmau: false,
      mangthai: false,
      choconbu: false,
      bingatxiu: false,
      viemkhop: false,
      viemgan: false,
      lao: false,
      caohuyetap: false,
      daithaoduong: false,
      benhtim: false,
      benhmau: false,
      benhthan: false,
      benhsuyen: false
    },
    tamly: {
      khotiepcan: false,
      khohoptac: false,
      yeucauthammy: false,
      tulamhai: false,
      loausohai: false,
      vandetaichinh: false
    },
    dental: {
      reasonHistory: '',
      dungtam: false,
      dungchi: false,
      dungbanchai: false,
      chaihangngay: false,
      khamdinhky: false,
      cachchairang: '',
      anthucphamcung: false,
      anthucphamchua: false,
      anthucphamngot: false,
      uongnuocgacoga: false
    },
    thoiquenrang: {
      nhaimotben: false,
      canmoi: false,
      nghienrangkhingu: false,
      nhaivatcung: false,
      tatdayluoi: false,
      nghienchatrang: false
    },

    khamngoaimat: {
      mota: ''
    },

    khamtrongmieng: {
      mota: ''
    },

    // page 2
    ngoaimat: '',
    trongmieng: '',
    cankhop: {
      anglephai: '',
      angletrai: '',
      canphu: '',
      canchia: '',
      biendo: '',
      dolech: '',
      tinhchat: '',
      row1col1: '',
      row1col2: '',
      row1col3: '',
      row2col2: '',
      row2col3: '',
      row3col2: '',
      row3col3: ''
    },
    congspee: '',
    congwilson: '',
    cankhopkhac: '',

    // page 3
    tiepxucrang: {
      row1col2: '',
      row1col3: '',
      row2col2: '',
      row2col3: '',
      row3col2: '',
      row3col3: ''
    },
    rang11: '',
    rang12: '',
    rang13: '',
    rang14: '',
    rang15: '',
    rang16: '',
    rang17: '',
    rang18: '',
    rang21: '',
    rang22: '',
    rang23: '',
    rang24: '',
    rang25: '',
    rang26: '',
    rang27: '',
    rang28: '',
    rang31: '',
    rang32: '',
    rang33: '',
    rang34: '',
    rang35: '',
    rang36: '',
    rang37: '',
    rang38: '',
    rang41: '',
    rang42: '',
    rang43: '',
    rang44: '',
    rang45: '',
    rang46: '',
    rang47: '',
    rang48: '',
    canlamsang: {
      phimquanhkhop: false,
      mauham: false,
      toancanh: false,
      mau: false,
      cancanh: false,
      conebeam: false,
      khac: '',
      nhanxet: ''
    },

    // page 4
    p4table1: {
      ngay: '',

      // lung lay
      lunglay81: '',
      lunglay71: '',
      lunglay61: '',
      lunglay51: '',
      lunglay41: '',
      lunglay31: '',
      lunglay21: '',
      lunglay11: '',
      lunglay12: '',
      lunglay22: '',
      lunglay32: '',
      lunglay42: '',
      lunglay52: '',
      lunglay62: '',
      lunglay72: '',
      lunglay82: '',

      // PII
      pii81: '',
      pii71: '',
      pii61: '',
      pii51: '',
      pii41: '',
      pii31: '',
      pii21: '',
      pii11: '',
      pii12: '',
      pii22: '',
      pii32: '',
      pii42: '',
      pii52: '',
      pii62: '',
      pii72: '',
      pii82: '',

      // GI
      gi81: '',
      gi71: '',
      gi61: '',
      gi51: '',
      gi41: '',
      gi31: '',
      gi21: '',
      gi11: '',
      gi12: '',
      gi22: '',
      gi32: '',
      gi42: '',
      gi52: '',
      gi62: '',
      gi72: '',
      gi82: '',

      // PPD
      ppd81: '',
      ppd71: '',
      ppd61: '',
      ppd51: '',
      ppd41: '',
      ppd31: '',
      ppd21: '',
      ppd11: '',
      ppd12: '',
      ppd22: '',
      ppd32: '',
      ppd42: '',
      ppd52: '',
      ppd62: '',
      ppd72: '',
      ppd82: '',

      // GM
      gm81: '',
      gm71: '',
      gm61: '',
      gm51: '',
      gm41: '',
      gm31: '',
      gm21: '',
      gm11: '',
      gm12: '',
      gm22: '',
      gm32: '',
      gm42: '',
      gm52: '',
      gm62: '',
      gm72: '',
      gm82: '',

      // CAL
      cal81: '',
      cal71: '',
      cal61: '',
      cal51: '',
      cal41: '',
      cal31: '',
      cal21: '',
      cal11: '',
      cal12: '',
      cal22: '',
      cal32: '',
      cal42: '',
      cal52: '',
      cal62: '',
      cal72: '',
      cal82: '',

      // BOP
      bop81: '',
      bop71: '',
      bop61: '',
      bop51: '',
      bop41: '',
      bop31: '',
      bop21: '',
      bop11: '',
      bop12: '',
      bop22: '',
      bop32: '',
      bop42: '',
      bop52: '',
      bop62: '',
      bop72: '',
      bop82: ''
    },

    p4table2: {
      // lung lay
      lunglay81: '',
      lunglay71: '',
      lunglay61: '',
      lunglay51: '',
      lunglay41: '',
      lunglay31: '',
      lunglay21: '',
      lunglay11: '',
      lunglay12: '',
      lunglay22: '',
      lunglay32: '',
      lunglay42: '',
      lunglay52: '',
      lunglay62: '',
      lunglay72: '',
      lunglay82: '',

      // PII
      pii81: '',
      pii71: '',
      pii61: '',
      pii51: '',
      pii41: '',
      pii31: '',
      pii21: '',
      pii11: '',
      pii12: '',
      pii22: '',
      pii32: '',
      pii42: '',
      pii52: '',
      pii62: '',
      pii72: '',
      pii82: '',

      // GI
      gi81: '',
      gi71: '',
      gi61: '',
      gi51: '',
      gi41: '',
      gi31: '',
      gi21: '',
      gi11: '',
      gi12: '',
      gi22: '',
      gi32: '',
      gi42: '',
      gi52: '',
      gi62: '',
      gi72: '',
      gi82: '',

      // PPD
      ppd81: '',
      ppd71: '',
      ppd61: '',
      ppd51: '',
      ppd41: '',
      ppd31: '',
      ppd21: '',
      ppd11: '',
      ppd12: '',
      ppd22: '',
      ppd32: '',
      ppd42: '',
      ppd52: '',
      ppd62: '',
      ppd72: '',
      ppd82: '',

      // GM
      gm81: '',
      gm71: '',
      gm61: '',
      gm51: '',
      gm41: '',
      gm31: '',
      gm21: '',
      gm11: '',
      gm12: '',
      gm22: '',
      gm32: '',
      gm42: '',
      gm52: '',
      gm62: '',
      gm72: '',
      gm82: '',

      // CAL
      cal81: '',
      cal71: '',
      cal61: '',
      cal51: '',
      cal41: '',
      cal31: '',
      cal21: '',
      cal11: '',
      cal12: '',
      cal22: '',
      cal32: '',
      cal42: '',
      cal52: '',
      cal62: '',
      cal72: '',
      cal82: '',

      // BOP
      bop81: '',
      bop71: '',
      bop61: '',
      bop51: '',
      bop41: '',
      bop31: '',
      bop21: '',
      bop11: '',
      bop12: '',
      bop22: '',
      bop32: '',
      bop42: '',
      bop52: '',
      bop62: '',
      bop72: '',
      bop82: ''
    },

    p4table3: {
      // lung lay
      lunglay81: '',
      lunglay71: '',
      lunglay61: '',
      lunglay51: '',
      lunglay41: '',
      lunglay31: '',
      lunglay21: '',
      lunglay11: '',
      lunglay12: '',
      lunglay22: '',
      lunglay32: '',
      lunglay42: '',
      lunglay52: '',
      lunglay62: '',
      lunglay72: '',
      lunglay82: '',

      // PII
      pii81: '',
      pii71: '',
      pii61: '',
      pii51: '',
      pii41: '',
      pii31: '',
      pii21: '',
      pii11: '',
      pii12: '',
      pii22: '',
      pii32: '',
      pii42: '',
      pii52: '',
      pii62: '',
      pii72: '',
      pii82: '',

      // GI
      gi81: '',
      gi71: '',
      gi61: '',
      gi51: '',
      gi41: '',
      gi31: '',
      gi21: '',
      gi11: '',
      gi12: '',
      gi22: '',
      gi32: '',
      gi42: '',
      gi52: '',
      gi62: '',
      gi72: '',
      gi82: '',

      // PPD
      ppd81: '',
      ppd71: '',
      ppd61: '',
      ppd51: '',
      ppd41: '',
      ppd31: '',
      ppd21: '',
      ppd11: '',
      ppd12: '',
      ppd22: '',
      ppd32: '',
      ppd42: '',
      ppd52: '',
      ppd62: '',
      ppd72: '',
      ppd82: '',

      // GM
      gm81: '',
      gm71: '',
      gm61: '',
      gm51: '',
      gm41: '',
      gm31: '',
      gm21: '',
      gm11: '',
      gm12: '',
      gm22: '',
      gm32: '',
      gm42: '',
      gm52: '',
      gm62: '',
      gm72: '',
      gm82: '',

      // CAL
      cal81: '',
      cal71: '',
      cal61: '',
      cal51: '',
      cal41: '',
      cal31: '',
      cal21: '',
      cal11: '',
      cal12: '',
      cal22: '',
      cal32: '',
      cal42: '',
      cal52: '',
      cal62: '',
      cal72: '',
      cal82: '',

      // BOP
      bop81: '',
      bop71: '',
      bop61: '',
      bop51: '',
      bop41: '',
      bop31: '',
      bop21: '',
      bop11: '',
      bop12: '',
      bop22: '',
      bop32: '',
      bop42: '',
      bop52: '',
      bop62: '',
      bop72: '',
      bop82: ''
    },

    p4table4: {
      // lung lay
      lunglay81: '',
      lunglay71: '',
      lunglay61: '',
      lunglay51: '',
      lunglay41: '',
      lunglay31: '',
      lunglay21: '',
      lunglay11: '',
      lunglay12: '',
      lunglay22: '',
      lunglay32: '',
      lunglay42: '',
      lunglay52: '',
      lunglay62: '',
      lunglay72: '',
      lunglay82: '',

      // PII
      pii81: '',
      pii71: '',
      pii61: '',
      pii51: '',
      pii41: '',
      pii31: '',
      pii21: '',
      pii11: '',
      pii12: '',
      pii22: '',
      pii32: '',
      pii42: '',
      pii52: '',
      pii62: '',
      pii72: '',
      pii82: '',

      // GI
      gi81: '',
      gi71: '',
      gi61: '',
      gi51: '',
      gi41: '',
      gi31: '',
      gi21: '',
      gi11: '',
      gi12: '',
      gi22: '',
      gi32: '',
      gi42: '',
      gi52: '',
      gi62: '',
      gi72: '',
      gi82: '',

      // PPD
      ppd81: '',
      ppd71: '',
      ppd61: '',
      ppd51: '',
      ppd41: '',
      ppd31: '',
      ppd21: '',
      ppd11: '',
      ppd12: '',
      ppd22: '',
      ppd32: '',
      ppd42: '',
      ppd52: '',
      ppd62: '',
      ppd72: '',
      ppd82: '',

      // GM
      gm81: '',
      gm71: '',
      gm61: '',
      gm51: '',
      gm41: '',
      gm31: '',
      gm21: '',
      gm11: '',
      gm12: '',
      gm22: '',
      gm32: '',
      gm42: '',
      gm52: '',
      gm62: '',
      gm72: '',
      gm82: '',

      // CAL
      cal81: '',
      cal71: '',
      cal61: '',
      cal51: '',
      cal41: '',
      cal31: '',
      cal21: '',
      cal11: '',
      cal12: '',
      cal22: '',
      cal32: '',
      cal42: '',
      cal52: '',
      cal62: '',
      cal72: '',
      cal82: '',

      // BOP
      bop81: '',
      bop71: '',
      bop61: '',
      bop51: '',
      bop41: '',
      bop31: '',
      bop21: '',
      bop11: '',
      bop12: '',
      bop22: '',
      bop32: '',
      bop42: '',
      bop52: '',
      bop62: '',
      bop72: '',
      bop82: ''
    },

    // page 5
    tomtat: '',
    chandoan1: '',
    chandoan2: '',
    chandoan3: '',
    chandoan4: '',
    chandoan5: '',

    // page 6
    row1: {
      luuy: '',
      xutri: ''
    },
    row2: {
      luuy: '',
      xutri: ''
    },
    row3: {
      luuy: '',
      xutri: ''
    },
    row4: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row5: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row6: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row7: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row8: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row9: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row10: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row11: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row12: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row13: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row14: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row15: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row16: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row17: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row18: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    row19: {
      loai: '',
      gia: '',
      sobuoi: ''
    },
    rowtotal: {
      gia: '',
      sobuoi: ''
    },

    // page 7
    ques1: 3,
    ques2: 3,
    ques3: '',
    ques4: 'no', // assuming 'no' as the default value
    ques5: 3,
    ques6: '',
    ques7: '',
    datepage7first: '',

    ques21: 3,
    ques22: 3,
    ques23: 3,
    ques24: 3,
    ques25: 'no', // assuming 'no' as the default value
    ques26: 3,
    benhnhangopy: '',
    datepage7second: '',

    // page 8
    p8row1: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row2: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row3: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row4: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row5: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row6: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row7: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row8: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row9: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row10: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row11: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row12: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row13: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row14: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },
    p8row15: {
      rang: '',
      noidung: '',
      svdieutri: '',
      svtrothu: '',
      mentor: '',
      note: ''
    },

    // page 9
    page9: {
      row1col1: '',
      row1col2: '',
      row1col3: '',
      row1col4: '',
      row2col1: '',
      row2col2: '',
      row2col3: '',
      row2col4: '',

      note1: '',
      note2: '',
      note3: '',
      note4: ''
    },

    // page 10
    giaotiep: {
      row1: '1',
      row2: '1',
      row3: '1',
      row4: '1',
      row5: '1',
      row6: '1',
      note: ''
    },

    tontrong: {
      row1: '1',
      row2: '1',
      row3: '1',
      row4: '1',
      note: ''
    },

    daoduc: {
      row1: '1',
      row2: '1',
      row3: '1',
      row4: '1',
      row5: '1',
      note: ''
    },

    thaido: {
      row1: '1',
      row2: '1',
      row3: '1',
      row4: '1',
      row5: '1',
      row6: '1',
      note: ''
    },

    chuanbi: {
      row1: '1',
      row2: '1',
      row3: '1',
      row4: '1',
      row5: '1',
      note: ''
    }
  },
  detail: {} as MedicalFormState,
  allDetail: {},
  editState: {} as MedicalFormState
}

export const getMedicalRecords = createAsyncThunk('medical-records/getMedicalRecords', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('document/list')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const addMedicalRecord = createAsyncThunk('medical-records/addMedicalRecord', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.post('medical-record/with-patient', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getMedicalRecordById = createAsyncThunk('medical-records/getMedicalRecord', async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.get(`medical-record/${id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const fetchListDoctor = createAsyncThunk('doctors/fetchListDoctors', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('doctor/list')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const documentUpload = createAsyncThunk('document/documentUpload', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.post('document/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getPatients = createAsyncThunk('patients/getPatients', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('patient/list')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getDocumentById = createAsyncThunk('document/getDocumentById', async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.get(`document/${id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateDocument = createAsyncThunk(
  'document/updateDocument',
  async (
    payload: {
      id: string
      formData: FormData
    },
    { rejectWithValue }
  ) => {
    try {
      const { id, formData } = payload
      const response = await api.put(`document/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response) {
        return response
      }
      return {}
    } catch (error: any) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

type CreatePatient = {
  name: string
  phoneNumber: string
}
export const createPatient = createAsyncThunk('patients/createPatient', async (payload: CreatePatient, { rejectWithValue }) => {
  try {
    const response = await api.post('patient/create', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

type CreateMedicalRecord = {
  data: object
  patientId?: string
  doctorId?: string
}

export const createMedicalRecord = createAsyncThunk('medical-records/createMedicalRecord', async (payload: CreateMedicalRecord, { rejectWithValue }) => {
  try {
    const response = await api.post('medical', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const medicalList = createAsyncThunk('medical-records/medicalList', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState
    const { query } = state.medical

    const params: any = {}

    if (query && query.trim() !== '') {
      params.q = query
    }

    const response = await api.get('medical/all', {
      params
    })

    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getMedicalById = createAsyncThunk('medical-records/getMedicalById', async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.get(`medical/${id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateMedical = createAsyncThunk('medical-records/updateMedicalRecord', async (payload: any, { rejectWithValue }) => {
  try {
    const { id, ...rest } = payload
    const response = await api.put(`medical/${id}`, rest)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const uploadMedicalFiles = createAsyncThunk('medical-records/uploadMedicalFiles', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.post('media/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const medicalSlice = createSlice({
  name: 'medical-records',

  initialState,

  reducers: {
    updateDataState: (state, action: PayloadAction<any>) => {
      state.dataState = {
        ...state.dataState,
        ...action.payload
      }
    },

    updateEditState: (state, action: PayloadAction<any>) => {
      state.editState = {
        ...state.editState,
        ...action.payload
      }
    },

    resetDataState: (state) => {
      state.dataState = {
        ...initialMedicalFormState,
        code: genCode()
      }
      state.doctorId = undefined
    },

    setDoctorId: (state, action: PayloadAction<string>) => {
      state.doctorId = action.payload
    },

    setFilter: (state, action) => {
      state.query = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(medicalList.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        return {
          ...state,
          total,
          items
        }
      })
      .addCase(getMedicalById.fulfilled, (state, action) => {
        const { data } = action.payload as any
        state.detail = data
        state.allDetail = action.payload
      })
      .addCase(createMedicalRecord.fulfilled, (state, _action) => {
        state.dataState = { ...initialMedicalFormState }
      })
  }
})

export const { updateDataState, resetDataState, updateEditState, setDoctorId, setFilter } = medicalSlice.actions
export default medicalSlice.reducer
