/* eslint-disable @typescript-eslint/no-unused-vars */
import type { GetProp, UploadProps } from 'antd'
import { Avatar, Button, Form, Image, Input, message, Upload } from 'antd'
import React, { useState } from 'react'
import { IoCameraOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { logout } from '../auth/reducers/authReducer'
import { updateProfilePic } from './reducers/accountReducer'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const Account: React.FC = () => {
  const dispatch = useDispatch<any>()
  const user = useSelector((state: RootState) => state.auth.user)
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [picUrl, setPicUrl] = useState<string>(`${process.env.REACT_APP_BASE_API_URL}/uploads/profile/${user?.profile?.profilePicture}`)

  const handleLogout = () => {
    dispatch(logout())
  }

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
    if (!isJpgOrPng) {
      message.error('Định dạng ảnh không hợp lệ!')
      return Upload.LIST_IGNORE
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Ảnh phải nhỏ hơn 5MB!')
      return Upload.LIST_IGNORE
    }
    return true
  }

  const customRequest = async (options: any) => {
    const { file } = options

    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('userId', user.id)

      const rs = await dispatch(updateProfilePic(formData)).unwrap()

      messageApi.success('Tải ảnh thành công!')
      setPicUrl(`${process.env.REACT_APP_BASE_API_URL}/uploads/profile/${rs.profilePicture}`)
    } catch (error) {
      messageApi.error('Thất bại!')
    }
  }

  return (
    <div className='flex h-full justify-center py-3'>
      {contextHolder}
      <div className='flex w-full max-w-none flex-col gap-5 sm:max-w-sm lg:max-w-md'>
        {user && (
          <>
            <div className='center'>
              <Avatar className='bg-primary text-3xl' size={64}>
                {user?.username[0]?.toUpperCase()}
              </Avatar>
            </div>

            <div className='relative'>
              <img
                className='h-full min-h-[50px] w-full rounded-[20px] object-cover'
                src={picUrl || 'https://placehold.co/600x400'}
                onError={(e) => {
                  e.currentTarget.onerror = null
                  e.currentTarget.src = 'https://placehold.co/600x400'
                }}
              />
              <Upload showUploadList={false} customRequest={customRequest} action='' beforeUpload={beforeUpload}>
                <div className='absolute inset-0 cursor-pointer rounded-[20px] bg-black/40 text-white opacity-0 transition-all center hover:opacity-100'>
                  <IoCameraOutline />
                </div>
              </Upload>
            </div>

            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage('')
                }}
                src={previewImage}
              />
            )}

            <div className='flex flex-col gap-5'>
              {user && (
                <Form form={form} layout='vertical'>
                  <Form.Item label='Username' className='mb-3'>
                    <Input className='gap-1' value={user.username} disabled />
                  </Form.Item>

                  {user?.doctor?.email && (
                    <Form.Item label='Email' className='mb-3'>
                      <Input className='gap-1' value={user?.doctor?.email} disabled />
                    </Form.Item>
                  )}

                  {user?.doctor?.name && (
                    <Form.Item label='Display Name' className='mb-3'>
                      <Input className='gap-1' value={user?.doctor?.name} disabled />
                    </Form.Item>
                  )}
                </Form>
              )}
            </div>
          </>
        )}

        <div className='flex justify-end'>
          <Button onClick={handleLogout}>Đăng xuất</Button>
        </div>
      </div>
    </div>
  )
}

export default Account
