import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

export interface DoctorState {
  items: any[]
  total: number
  departments: any[]
}

const initialState: DoctorState = {
  items: [],
  total: 0,
  departments: []
}

/*
 *****************************************
 *
 *
 */

interface CreateDoctorDto {
  username: string
  password: string
  name: string
  departmentId: number
  specialization?: string
  phoneNumber?: string
  email?: string
}

export const getDoctors = createAsyncThunk('doctors/getDoctors', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('doctor/list')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createDoctor = createAsyncThunk('doctors/createDoctor', async (payload: CreateDoctorDto, { rejectWithValue }) => {
  try {
    const response = await api.post('doctor/create-doctor', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

interface UpdateHeadDoctorDto {
  doctorId: number
  setHead: boolean
}

export const updateHeadDoctor = createAsyncThunk('doctors/setHeadDoctor', async (payload: UpdateHeadDoctorDto, { rejectWithValue }) => {
  try {
    const rs = await api.put(`doctor/${payload.doctorId}/set-head`, { setHead: payload.setHead })

    if (rs) {
      return { doctorId: payload.doctorId, status: payload.setHead }
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

interface AddDepartmentDto {
  name: string
  description?: string
}
export const addDepartment = createAsyncThunk('doctors/addDepartment', async (payload: AddDepartmentDto, { rejectWithValue }) => {
  try {
    const response = await api.post('department', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const fetchDepartments = createAsyncThunk('doctors/fetchDepartments', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('department/all')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const doctorSlice = createSlice({
  name: 'doctors',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDoctors.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        return {
          ...state,
          total,
          items
        }
      })
      .addCase(updateHeadDoctor.fulfilled, (state, action) => {
        const { doctorId, status } = action.payload as any
        const index = state.items.findIndex((item) => item.id === doctorId)
        if (index > -1) {
          state.items[index].isHead = status
        }
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        const { items } = action.payload as any
        state.departments = items
      })
  }
})

// export const { setFilter } = logSlice.actions
export default doctorSlice.reducer
