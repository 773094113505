import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

export interface UserState {
  total: number
  items?: any[]
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
  }
}

const initialState: UserState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 20,
    search: undefined,
    sorts: '-createdAt'
  }
}

export const getUsers = createAsyncThunk('users/getList', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState

    const params = { ...state.users.filter }

    if (!params.search) {
      delete params.search
    }

    const response = await api.get('users', {
      params
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getUserDetail = createAsyncThunk('users/getDetail', async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.get(`users/${id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const addUser = createAsyncThunk('users/addUser', async (data: any, { rejectWithValue }) => {
  try {
    const response = await api.post('users', data)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.delete(`users/${id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateUser = createAsyncThunk('users/updateUser', async (data: any, { rejectWithValue }) => {
  try {
    const { id, name } = data
    const response = await api.put(`users/${id}`, { name })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const userSlice = createSlice({
  name: 'users',

  initialState,

  reducers: {
    setUserState: (state, action) => {
      return {
        ...state,
        ...action.payload,
        filter: {
          ...state.filter,
          ...action.payload.filter
        }
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const { total, items } = action.payload as any
      state.total = total
      state.items = items
    })

    builder.addCase(addUser.fulfilled, (state, action) => {
      state.total += 1
      state.items?.unshift(action.payload)
    })

    builder.addCase(deleteUser.fulfilled, (state) => {
      state.total -= 1
    })

    builder.addCase(updateUser.fulfilled, (state, action: any) => {
      state.items = state.items?.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    })
  }
})

export const { setUserState } = userSlice.actions
export default userSlice.reducer
