import { App, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { getMedicalById, updateEditState, updateMedical } from '../../reducers/medicalReducer'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import Page5 from './Page5'
import Page6 from './Page6'
import Page7 from './Page7'
import Page8 from './Page8'
import Page9 from './Page9'
import Page10 from './Page10'

const EditLayout: React.FC = () => {
  const dispatch = useDispatch<any>()
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  const data: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const totalPages = 10
  const page = searchParams.get('page') || '1'
  const [loading, setLoading] = useState(true)
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null)
  const navigate: NavigateFunction = useNavigate()
  const { message } = App.useApp()

  const handleUpdate = async () => {
    try {
      const rs = await dispatch(
        updateMedical({
          id,
          data,
          doctorId: selectedDoctor
        })
      ).unwrap()
      navigate(`/medical-records/${rs.id}?page=${page}`)
      message.success('Cập nhật thành công')
    } catch (error) {}
  }

  const fetchMedicalRecord = async () => {
    try {
      const rs = await dispatch(getMedicalById(id as string)).unwrap()
      dispatch(updateEditState(rs.data))
      setSelectedDoctor(1)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const goToPage = (pageNumber: string) => {
    if (pageNumber === page) return
    navigate(`/medical-records/${id}/edit?page=${pageNumber}`)
  }

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

  const renderPage = () => {
    switch (page) {
      case '1':
        return <Page1 />
      case '2':
        return <Page2 />
      case '3':
        return <Page3 />
      case '4':
        return <Page4 />
      case '5':
        return <Page5 />
      case '6':
        return <Page6 />
      case '7':
        return <Page7 />
      case '8':
        return <Page8 />
      case '9':
        return <Page9 />
      case '10':
        return <Page10 />
      default:
        return <Page1 />
    }
  }

  useEffect(() => {
    fetchMedicalRecord()
  }, [id])

  if (!data || loading) {
    return <p>Loading...</p>
  }

  return (
    <div className='form-medical'>
      <div className='flex justify-between'>
        <div className='flex justify-center space-x-4'>
          {pages.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => goToPage(pageNumber.toString())}
              type={page === pageNumber.toString() ? 'primary' : 'default'}
              className='w-8'
            >
              {pageNumber}
            </Button>
          ))}
        </div>
        <div className='flex gap-3'>
          <Button
            onClick={() => {
              navigate(`/medical-records/${id}?page=${page}`)
            }}
          >
            Quay lại
          </Button>
          <Button type='primary' onClick={handleUpdate}>
            Cập nhật
          </Button>
        </div>
      </div>

      {renderPage()}
    </div>
  )
}

export default EditLayout
