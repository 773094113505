import { Button, message, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import type { RootState } from '@/store'

import { getDoctors, updateHeadDoctor } from './reducers/doctorReducer'

interface Doctor {
  id: number
  name: string
  specialization: string
  phoneNumber: string
  email: string
  departmentId: number
  department?: any
  isHead: boolean
  isDel: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

const DoctorManager: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const items = useSelector((state: RootState) => state.doctor.items) as Doctor[]
  const [loading, setLoading] = useState<boolean>(true)

  const fectchData = async () => {
    setLoading(true)
    try {
      await dispatch(getDoctors())
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const setHeadDoctor = async (doctorId: number) => {
    try {
      await dispatch(updateHeadDoctor({ doctorId, setHead: true }))
      message.success('Đã đặt bác sĩ làm trưởng khoa')
    } catch (error) {}
  }

  const demoteHeadDoctor = async (doctorId: number) => {
    try {
      await dispatch(updateHeadDoctor({ doctorId, setHead: false }))
      message.success('Đã hủy bác sĩ làm trưởng khoa')
    } catch (error) {}
  }

  useEffect(() => {
    fectchData()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Specialization',
      dataIndex: 'specialization',
      key: 'specialization'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Department',
      key: 'department',
      render: (record: Doctor) => record.department?.name
    },
    {
      title: 'Chức vụ',
      dataIndex: 'isHead',
      key: 'isHead',
      render: (value: boolean) => (value ? 'Trưởng khoa' : 'Bác sĩ')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: Doctor) => (
        <Button type='primary' danger={record.isHead} onClick={() => (record.isHead ? demoteHeadDoctor(record.id) : setHeadDoctor(record.id))}>
          {record.isHead ? 'Demote' : 'Set Head'}
        </Button>
      )
    }
  ]

  return (
    <div>
      <div className='mb-4 flex justify-end'>
        <Button type='primary' onClick={() => navigate('add-doctor')}>
          Thêm bác sĩ
        </Button>
      </div>
      <Table dataSource={items} columns={columns} rowKey='id' loading={loading} rowClassName='cursor-pointer' />
    </div>
  )
}

export default DoctorManager
